.image-slider-container {
  text-align: center;
  max-width: 100%;
  max-height: 80vh;
  display: block;
  overflow: hidden; /* Esto asegurará que no haya desbordamiento fuera del contenedor */
}

.images-wrapper {
  display: flex;
  justify-content: center;
  align-items: center; /* Centra la imagen verticalmente */
  height: 300px !important;
  margin: 0 15px !important; 
}

.images-wrapper img {
  width: 95%; 
  height: 100%; /* Asegura que la imagen llene el contenedor */
  object-fit: cover; /* Mantiene la relación de aspecto, pero recorta lo que sobre */
  padding: 10px;
  border-radius: 5px;
  flex: 0 0 auto; 
}