.text8 {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 150%;
}
.title4 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.arrow-icon {
  position: relative;
  width: 24px;
  height: 24px;
}
.header7,
.text9 {
  align-self: stretch;
}
.header7 {
  background-color: var(--color-aliceblue-200);
  border-bottom: 1px solid var(--color-cornflowerblue-100);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-sm) var(--padding-xl);
  gap: var(--gap-29xl);
}
.text9 {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 150%;
}
.body4,
.dropdown4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.body4 {
  align-self: stretch;
  border-bottom: 1px solid var(--color-whitesmoke-100);
  justify-content: center;
  padding: var(--padding-5xl);
  color: var(--color-gray-200);
}
.dropdown4 {
  border-radius: var(--br-3xs);
  background-color: var(--neutrals-50-white);
  border: 1px solid var(--color-aliceblue-200);
  overflow: hidden;
  justify-content: flex-start;
}
.body5,
.dropdown4,
.text11 {
  align-self: stretch;
}
.text11 {
  position: relative;
  line-height: 140%;
}
.body5 {
  border-bottom: 1px solid var(--color-whitesmoke-200);
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-5xl);
  color: var(--color-dimgray-200);
  font-family: var(--font-roboto);
}
.pgina-web2 {
  text-decoration: underline;
  color: var(--color-dodgerblue-100);
}
.text14 {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 150%;
  display: flex;
  align-items: center;
  width: 516px;
  flex-shrink: 0;
}
.faqs1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-cornflowerblue-100);
  font-family: var(--font-poppins);
}
