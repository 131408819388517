.logo-icon2 {
  position: relative;
  width: 320px;
  height: 49px;
  display: none;
}
.image-97-icon2 {
  position: relative;
  width: 116px;
  height: 43px;
  object-fit: cover;
}
.instagram-icon2 {
  position: relative;
  width: 32px;
  height: 32px;
}
.redes-sociales2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.header-12,
.header2,
.sections2 {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.sections2 {
  flex-direction: row;
  justify-content: space-between;
}
.header-12,
.header2 {
  flex-direction: column;
}
.header-12 {
  background-color: var(--color-darkslateblue);
  justify-content: center;
  padding: var(--padding-5xl) var(--padding-81xl);
  gap: var(--gap-5xl);
}
.header2 {
  background-color: var(--neutrals-50-white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  justify-content: flex-start;
}
