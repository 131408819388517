.label2 {
  position: relative;
  line-height: 156%;
}
.label-container {
  background-color: var(--neutrals-50-white);
  height: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-9xs);
  box-sizing: border-box;
}
.value {
  flex: 1;
  position: relative;
  letter-spacing: 0.15px;
  line-height: 143%;
}
.value-container {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  flex-direction: row;
}
.content1,
.input,
.sizemedium-stateenabled-h,
.value-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.content1 {
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-mini) 0;
  font-size: var(--text-subtitle2-size);
  color: var(--text-primary);
}
.input,
.sizemedium-stateenabled-h {
  flex-direction: column;
}
.input {
  align-self: stretch;
  border-radius: var(--br-5xs);
  border: 1px solid var(--neutrals-300);
  padding: 0 var(--padding-xs);
}
.sizemedium-stateenabled-h {
  text-align: left;
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
  font-family: var(--text-subtitle2);
  flex: 1;
}
