.logo-icon1 {
  position: relative;
  width: 320px;
  height: 49px;
  display: none;
}
.image-97-icon1 {
  position: relative;
  width: 116px;
  height: 43px;
  object-fit: cover;
}
.instagram-icon1 {
  position: relative;
  width: 32px;
  height: 32px;
}
.redes-sociales1,
.sections1 {
  display: flex;
  flex-direction: row;
}
.redes-sociales1 {
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.sections1 {
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
}
.header-11,
.header1 {
  display: flex;
  flex-direction: column;
}
.header-11 {
  background-color: var(--color-darkslateblue);
  width: 1440px;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xl) var(--padding-81xl);
  box-sizing: border-box;
  gap: var(--gap-5xl);
}
.header1 {
  background-color: var(--neutrals-50-white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  align-items: flex-start;
  justify-content: flex-start;
}
.banner-item,
.ios-1-icon2 {
  position: absolute;
  width: 1440px;
}
.ios-1-icon2 {
  top: calc(50% - 540.2px);
  left: calc(50% - 720px);
  height: 1080px;
  object-fit: cover;
}
.banner-item {
  top: 0.3px;
  left: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.5), transparent);
  height: 643px;
}
.venta2 {
  position: relative;
  line-height: 120%;
  font-weight: 600;
}
.pill2 {
  border-radius: var(--br-31xl);
  background-color: var(--color-deepskyblue);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-base);
}
.hostal-en-calama2 {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-29xl);
  letter-spacing: -0.5px;
  line-height: 120%;
  color: var(--color-cornflowerblue-100);
}
.banner2,
.content4 {
  background-color: var(--neutrals-50-white);
}
.content4 {
  position: absolute;
  top: 423.3px;
  left: 100px;
  border-radius: var(--br-5xl) var(--br-5xl) 0 0;
  width: 520px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xl);
  box-sizing: border-box;
  gap: var(--gap-5xl);
}
.banner2 {
  position: relative;
  width: 1440px;
  height: 643px;
  overflow: hidden;
  flex-shrink: 0;
  text-align: left;
}
.precio-de-venta1 {
  font-weight: 600;
}
.clp2,
.precio-de-venta1,
.usd-16950-uf2 {
  position: relative;
  line-height: 140%;
}
.clp2 {
  font-size: var(--font-size-xl);
  font-weight: 600;
}
.descuento7,
.usd-16950-uf-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  font-size: var(--font-size-33xl);
}
.descuento7 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--color-cornflowerblue-200);
  height: 199px;
  padding: var(--padding-5xs) var(--padding-base);
  box-sizing: border-box;
  gap: var(--gap-base);
  font-size: var(--font-size-5xl);
  color: var(--color-cornflowerblue-100);
}
.marker-01-icon1 {
  position: relative;
  width: 50px;
  height: 50px;
}
.brasilia-1836-y1 {
  align-self: stretch;
  position: relative;
  line-height: 140%;
  font-weight: 600;
}
.cards2,
.envo4 {
  grid-column: span 2;
  text-align: center;
}
.envo4 {
  border-radius: var(--br-5xs);
  border: 2px solid var(--color-lightsteelblue);
  flex-direction: column;
  padding: var(--padding-5xs) var(--padding-5xl);
}
.cards2 {
  grid-template-columns: repeat(10, 1fr);
  gap: 16px;
  display: grid;
  flex-direction: row;
}
.descuento-inner,
.group-icon {
  position: relative;
  width: 48px;
  height: 51.5px;
}
.group-icon {
  width: 49.5px;
  height: 40.1px;
}
.envo-child1,
.envo-inner {
  position: relative;
  width: 30px;
  height: 51px;
}
.envo-child1 {
  width: 41px;
  height: 56px;
}
.button-group,
.montorecepcinubicacin1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.montorecepcinubicacin1 {
  flex-direction: column;
}
.detalle-de-producto1 {
  position: relative;
  font-size: var(--font-size-13xl);
  font-weight: 600;
  color: var(--color-cornflowerblue-100);
}
.lorem-ipsum-dolor2 {
  align-self: stretch;
  position: relative;
}
.content5 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  text-align: left;
}
.frame-inner {
  border-radius: var(--br-31xl);
  width: 40px;
  height: 40px;
  object-fit: contain;
}
.img-icon3 {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 300px;
  object-fit: cover;
}
.arrow-right-icon1 {
  position: relative;
  width: 24px;
  height: 24px;
}
.arrow-right-container {
  border-radius: var(--br-31xl);
  background-color: var(--color-cornflowerblue-200);
  backdrop-filter: blur(8px);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs);
}
.frame-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-21xl);
}
.sliders-child3,
.sliders-child4 {
  position: relative;
  border-radius: 50%;
  background-color: var(--color-cornflowerblue-100);
  width: 8px;
  height: 8px;
}
.sliders-child4 {
  background-color: var(--color-cornflowerblue-200);
}
.sliders1 {
  width: 153.8px;
  height: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.galera1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xl);
}
.block-11,
.block31 {
  background-color: var(--neutrals-50-white);
  width: 100%;
  box-sizing: border-box;
}
.block31 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-31xl) var(--padding-81xl);
  gap: var(--gap-41xl);
  color: var(--color-gray-200);
}
.block-11 {
  padding: var(--padding-81xl);
  gap: var(--gap-29xl);
  font-size: var(--font-size-21xl);
  color: var(--color-cornflowerblue-100);
}
.all-content-child,
.all-content2,
.block-11 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.all-content-child {
  align-self: stretch;
  background-color: var(--color-aliceblue-200);
  padding: var(--padding-81xl) 0;
}
.all-content2 {
  position: absolute;
  top: -0.3px;
  left: 0;
}
.home-item {
  position: absolute;
  top: 80px;
  left: 0;
  background-color: var(--color-gray-400);
  width: 1440px;
  height: 886px;
}
.estoy-interesado {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.text-fieldoutlined-group {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-5xl);
}
.vector-icon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.estoy-interesado-parent,
.frame-div {
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.frame-div {
  align-self: stretch;
  background-color: var(--color-aliceblue-100);
  align-items: center;
  padding: var(--padding-5xs) 0;
  gap: var(--gap-5xs);
  font-size: var(--font-size-xl);
}
.estoy-interesado-parent {
  position: absolute;
  top: calc(50% - 158px);
  left: calc(50% - 458px);
  background-color: var(--neutrals-50-white);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  width: 916px;
  align-items: flex-end;
  padding: var(--padding-21xl);
  box-sizing: border-box;
  gap: var(--gap-5xl);
  font-size: var(--font-size-13xl);
  color: var(--color-cornflowerblue-100);
}
.home2 {
  position: relative;
  background-color: var(--color-gray-100);
  width: 100%;
  height: 966px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--neutrals-50-white);
  font-family: var(--font-poppins);
}


/* Pantallas medianas (tabletas, 768px y superior) */
@media (min-width: 768px) {
    .logo-icon1 {
        display: block; /* Ejemplo: mostrar el logo en tabletas y dispositivos más grandes */
    }
    .image-97-icon1 {
        width: 15%; /* Uso de porcentajes para responsividad */
    }

}

/* Pantallas pequeñas (teléfonos móviles, menos de 768px) */
@media (max-width: 767px) {
    .sections1 {
        flex-direction: column; /* Cambio en la dirección del flex para pantallas pequeñas */
    }
    .redes-sociales1 {
        justify-content: center; /* Centrar elementos en pantallas pequeñas */
    }
    .image-97-icon1 {
        width: 30%; /* Ajustar el tamaño de la imagen para pantallas pequeñas */
    }
    .block31 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 15px;
      gap: var(--gap-41xl);
      color: var(--color-gray-200);
    }
    .montorecepcinubicacin1 {
      align-self: stretch;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: var(--gap-base);
    }
    .montorecepcinubicacin1 {
      flex-direction: column;
    }

  .descuento7,
  .usd-16950-uf-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
    font-size: 3dvh;
  }
  .cards2,
  .envo4 {
    text-align: center;
    grid-column: span 5 ;
  }
}
