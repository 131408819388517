.quiero-que-me1 {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.text-fieldoutlined-parent1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-5xl);
}
.quiero-que-me-contacten-group {
  background-color: var(--neutrals-50-white);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  width: 916px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: var(--padding-21xl);
  box-sizing: border-box;
  gap: var(--gap-5xl);
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--color-cornflowerblue-100);
  font-family: var(--font-poppins);
}
