.modal-background {
    position: fixed; /* Positioning relative to the viewport */
    top: 0;
    left: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
    display: flex;
    align-items: center; /* Center the modal vertically */
    justify-content: center; /* Center the modal horizontally */
    z-index: 10; /* Ensure it's above other items */
  }
  
  .modal-container {
      background: #FFFFFF;
      padding: 2rem;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      width: 90%;
      max-width: 1080px; /* Ajuste según sus necesidades */
      margin: auto; /* Centrar el formulario en el contenedor */
      box-sizing: border-box;
      z-index: 11;
  }
  
  .modal-container h1 {
      color: #196fc6;
      text-align: center;
      font-size: 2.5rem;
      margin-bottom: 2rem;
      grid-column: 1 / -1;
  }
  
  .modal-container p {
      text-align: center;
      margin-bottom: 2rem;
  }
  .frame-div  {
      grid-column: 1 / -1;
    }
  
.price-input {
    grid-column: 1 / -1;
    }
  .modal-container form {
      display: grid;
      grid-template-columns: repeat(3, 1fr); /* Crea tres columnas */
      grid-gap: 1rem; /* Espacio entre las columnas */
  }
  
  .offer-button {
      justify-self: center;
      margin-top: 1rem;
      margin-bottom: 1rem;
      width: 50%;
      background-color:#196FC6;
      color: white;
      border: none;
      border-radius: 10px;
      padding: 0.7rem 1rem;
      cursor: pointer;
      transition: background-color 0.3s ease;
  
  }

  
  .interested-button:hover {
      background-color: #0f4e8c;
  }
  
  .close {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      color: #ffffff;
      font-size: 1.5rem;
      transition: color 0.3s ease;
  
  }
  

  
  @media (max-width: 768px) {
      .modal-container form {
        grid-template-columns: 1fr; /* Todo en una sola columna para dispositivos móviles */
      }
      .modal-container {
        width: 90%;
        height: 90%;
      }
    }
    