.banner {
  overflow: hidden;
  max-height: 70vh;
  position: relative;
  
}

.ios-1-icon {
  top: calc(50% - 540.2px);
  left: calc(50% - 720px);
  width: 100%;
  object-fit: cover;
}
.venta {
  position: relative;
  line-height: 120%;
  font-weight: 600;
}
.pill {
  border-radius: var(--br-31xl);
  background-color: var(--color-deepskyblue);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-base);
}
.hostal-en-calama {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-29xl);
  letter-spacing: -0.5px;
  line-height: 120%;
  color: var(--color-cornflowerblue-100);
}

.content {
  position: absolute;
  /* Use viewport units or percentages instead of fixed pixels */
  bottom: 0;
  left: 10vw; /* Adjust this value as needed */
  
  border-radius: var(--br-5xl) var(--br-5xl) 0 0;
  background-color: var(--neutrals-50-white);

  /* Width can be a percentage of the viewport width */
  width: 80vw; /* Adjust this value as needed */
  max-width: 520px; /* Optional: to prevent the content from getting too wide on large screens */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xl);
  box-sizing: border-box;
  gap: var(--gap-5xl);
}

/* Media query for smaller screens */
@media (max-width: 750px) {
  .content {
    left: 0vw; /* Adjust for smaller screens */
    width: 70vw;
/* Adjust for smaller screens */
    /* Adjust padding and gap for smaller screens if needed */
  }
  .hostal-en-calama {
    align-self: stretch;
    position: relative;
    font-size: 20px;
    letter-spacing: -0.5px;
    line-height: 120%;
    color: var(--color-cornflowerblue-100);
  }
  .pill {
    border-radius: var(--br-31xl);
    background-color: var(--color-deepskyblue);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    padding: var(--padding-5xs) var(--padding-base);
  }
}

.all-content,
.home {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.home {
  position: relative;
  background-color: var(--color-gray-100);
  width: 100%;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--neutrals-50-white);
  font-family: var(--font-poppins);
}
