.label1 {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.1px;
  line-height: 143%;
}
.button,
.label {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.button {
  border-radius: var(--br-xs);
}
.stateprimary-contentlabel {
  border-radius: var(--br-5xs);
  background-color: var(--color-primary-800);
  width: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs) var(--padding-xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--text-subtitle2-size);
  color: var(--neutrals-50-white);
  font-family: var(--text-subtitle2);
}
