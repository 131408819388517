.redes-sociales3 {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-21xl);
  font-weight: 600;
}
.ponte-en-contacto {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.15px;
  line-height: 140%;
  color: var(--color-gray-200);
}
.home-05-icon {
  position: relative;
  width: 40px;
  height: 40px;
}
.portal-inmobiliario {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.15px;
  line-height: 140%;
}
.location,
.location-parent {
  display: flex;
  justify-content: flex-start;
}
.location {
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--color-cornflowerblue-100);
  height: 130px;
  flex-direction: column;
  align-items: center;
  padding: var(--padding-5xl) var(--padding-base);
  box-sizing: border-box;
  gap: var(--gap-base);
}
.location-parent {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-end;
  gap: var(--gap-5xl);
}
.lunes-y-viernes {
  margin: 0;
}
.content7,
.frame-parent2,
.mail,
.phone-parent,
.phone3 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.phone3 {
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--color-cornflowerblue-100);
  height: 130px;
  flex-direction: column;
  padding: var(--padding-5xl) var(--padding-base);
  box-sizing: border-box;
  gap: var(--gap-base);
}
.content7,
.frame-parent2,
.mail,
.phone-parent {
  align-self: stretch;
}
.mail {
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--color-cornflowerblue-100);
  flex-direction: column;
  padding: var(--padding-5xl) var(--padding-base);
  gap: var(--gap-base);
}
.content7,
.frame-parent2,
.phone-parent {
  flex-direction: row;
  gap: var(--gap-3xl);
}
.content7,
.frame-parent2 {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-5xl);
  color: var(--neutrals-50-white);
}
.content7 {
  background-color: var(--neutrals-50-white);
  padding: var(--padding-31xl) var(--padding-81xl);
  gap: var(--gap-13xl);
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--color-cornflowerblue-100);
  font-family: var(--font-poppins);
}

.content7 a {
  text-decoration: none;
  color: white;
}



@media (max-width: 767px) {
  .content7 {
    background-color: var(--neutrals-50-white);
    padding: 10px;
    gap: var(--gap-13xl);
    text-align: center;
    font-size: var(--font-size-lg);
    color: var(--color-cornflowerblue-100);
    font-family: var(--font-poppins);
  }
  .location,
  .location-parent {
  flex-direction: column;
  }
  .location {
    width: 100%;
    border-radius: var(--br-5xs);
    background-color: var(--color-cornflowerblue-100);
    height: 130px;

    padding: 30px;
    box-sizing: border-box;
    gap: var(--gap-base);
  }

  .phone3{
    width: 100%;
  }

  .phone-parent {
    align-self: stretch;
    flex-direction: column;
    align-items: flex-end;
    gap: var(--gap-5xl);
  }
}


