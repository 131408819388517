.frame-icon {
  border-radius: var(--br-31xl);
  width: 40px;
  height: 40px;
  object-fit: contain;
}
.img-icon6 {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 300px;
  object-fit: cover;
}
.arrow-right-icon2 {
  position: relative;
  width: 24px;
  height: 24px;
}
.arrow-right-frame {
  border-radius: var(--br-31xl);
  background-color: var(--color-cornflowerblue-200);
  backdrop-filter: blur(8px);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs);
}
.frame-parent1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-21xl);
}
.sliders-child10,
.sliders-child9 {
  position: relative;
  border-radius: 50%;
  background-color: var(--color-cornflowerblue-100);
  width: 8px;
  height: 8px;
}
.sliders-child10 {
  background-color: var(--color-cornflowerblue-200);
}
.button-parent1,
.galera2,
.sliders2 {
  display: flex;
  flex-direction: row;
  gap: var(--gap-base);
}
.sliders2 {
  width: 153.8px;
  height: 8px;
  align-items: flex-start;
  justify-content: flex-start;
}
.button-parent1,
.galera2 {
  align-self: stretch;
  align-items: center;
  justify-content: center;
}
.galera2 {
  flex-direction: column;
  gap: var(--gap-5xl);
}
