/* MyContactForm.css */

/* Estilos del contenedor principal */
.formulario-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem;
  background: #E2F1FB;
}

/* Estilos del formulario */
.formulario-container form {
  background: #FFFFFF;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 1080px; /* Ajuste según sus necesidades */
  margin: auto; /* Centrar el formulario en el contenedor */
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Crea tres columnas */
  grid-gap: 1rem; /* Espacio entre las columnas */
}

/* El h1 y textarea ocupan el 100% del ancho del formulario */
.formulario-container form h1{
  grid-column: 1 / -1;
}



/* Estilos para los encabezados */
h1 {
  color: #196fc6;
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

/* Estilos para grupos de entrada */
.input-group {
  display: grid;
  position: relative;
  flex-direction: column;
}

/* Estilos para los labels */
.input-group label {
  position: absolute;
  left: 1rem;
  top: -0.8rem;
  background: #FFF;
  padding: 0 0.4rem;
  color: #333;
  font-size: 16px;
  transition: all 0.3s ease;
}

/* Estilos para inputs y textarea */
.input-group input{
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-group input:focus,
.input-group textarea:focus {
  border-color: #196fc6;
}

.input-group input:not(:placeholder-shown) + label,
.input-group textarea:not(:placeholder-shown) + label {
  top: -1.4rem;
  left: 0.8rem;
  font-size: 14px;
}

.textarea-full {
  grid-column: 1 / -1; /* Aseguramos que el textarea ocupe todas las columnas */
}

/* Estilos para el área de texto dentro de la nueva clase */
.textarea-full textarea {
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-height: 150px;
  resize: vertical;
}

/* El área de texto ya está configurada para ocupar el 100% del ancho */

/* Estilos del botón */
button {
  padding: 1rem 1.5rem;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: background-color 0.3s ease;
  grid-column: 1 / -1; /* Hace que el botón ocupe el ancho completo del formulario */
  justify-self: center; /* Centra el botón */
  margin-top: 1rem;
}

button:hover {
  background-color: #0056b3;
}

/* Estilos para pantallas pequeñas */
@media (max-width: 768px) {
  .formulario-container form {
    grid-template-columns: 1fr; /* Todo en una sola columna para dispositivos móviles */
  }
}
