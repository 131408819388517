.quiero-que-me {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.text-fieldoutlined-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-5xl);
}
.quiero-que-me-contacten-parent {
  border-radius: var(--br-5xs);
  background-color: var(--neutrals-50-white);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: var(--padding-21xl);
  box-sizing: border-box;
  gap: var(--gap-5xl);
}
.all-content-inner1 {
  align-self: stretch;
  background-color: var(--color-aliceblue-200);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-81xl) 0;
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--color-cornflowerblue-100);
  font-family: var(--font-poppins);
}

@media (max-width: 767px) {

}