.image-97-icon3 {
  position: relative;
  width: 116px;
  height: 43px;
  object-fit: cover;
}
.santiago-chile {
  flex: 1;
  position: relative;
  letter-spacing: 0.15px;
  line-height: 143%;
  font-weight: 600;
}
.location1 {
  width: 304px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-2xs);
}
.instagram-icon3 {
  position: relative;
  width: 32px;
  height: 32px;
}
.block-13,
.redes-sociales4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.block-13 {
  flex: 1;
  flex-direction: column;
}
.casa-matriz {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.1px;
  line-height: 143%;
}
.phone4 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-2xs);
}
.vuesaxlinearmobile-icon {
  position: relative;
  width: 11px;
  height: 15px;
}
.vuesaxlinearmobile-wrapper {
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.mail-01-icon {
  position: relative;
  width: 20px;
  height: 20px;
}
.all-content-inner2,
.block-1-parent,
.block-3 {
  align-items: flex-start;
  justify-content: flex-start;
}
.block-3 {
  flex: 1;
  display: none;
  flex-direction: column;
  gap: var(--gap-base);
}
.all-content-inner2,
.block-1-parent {
  align-self: stretch;
  display: flex;
}
.block-1-parent {
  flex-direction: row;
  gap: var(--gap-21xl);
}
.all-content-inner2 {
  background-color: var(--color-darkslateblue);
  flex-direction: column;
  padding: var(--padding-11xl) var(--padding-81xl);
  text-align: left;
  font-size: var(--text-subtitle2-size);
  color: var(--neutrals-50-white);
  font-family: var(--font-poppins);
}
