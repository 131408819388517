@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-poppins: Poppins;
  --font-roboto: Roboto;
  --text-subtitle2: Montserrat;
  --font-raleway: Raleway;

  /* font sizes */
  --text-subtitle2-size: 14px;
  --font-size-lg: 18px;
  --font-size-21xl: 40px;
  --font-size-xs: 12px;
  --font-size-base: 16px;
  --font-size-13xl: 32px;
  --font-size-xl: 20px;
  --font-size-33xl: 52px;
  --font-size-5xl: 24px;
  --font-size-29xl: 48px;

  /* Colors */
  --color-gray-100: #fafafa;
  --color-gray-200: #242424;
  --color-gray-300: #202124;
  --text-primary: #212121;
  --color-gray-400: rgba(0, 0, 0, 0.3);
  --color-darkslateblue: #48529a;
  --neutrals-50-white: #fff;
  --color-cornflowerblue-100: #196fc6;
  --color-cornflowerblue-200: rgba(97, 178, 255, 0.1);
  --color-aliceblue-100: #eff7ff;
  --color-aliceblue-200: #e2f1fb;
  --neutrals-300: #aeaeae;
  --color-dimgray-100: #696a6c;
  --text-secondary: #666;
  --color-dimgray-200: #656565;
  --color-whitesmoke-100: #efefef;
  --color-whitesmoke-200: #eee;
  --color-dodgerblue-100: #1890ff;
  --color-deepskyblue: #63afec;
  --color-lightsteelblue: rgba(186, 219, 247, 0.5);
  --color-primary-800: #007b91;

  /* Gaps */
  --gap-21xl: 40px;
  --gap-base: 16px;
  --gap-2xs: 11px;
  --gap-xl: 20px;
  --gap-13xl: 32px;
  --gap-5xl: 24px;
  --gap-3xl: 22px;
  --gap-29xl: 48px;
  --gap-41xl: 60px;
  --gap-5xs: 8px;

  /* Paddings */
  --padding-11xl: 30px;
  --padding-81xl: 100px;
  --padding-31xl: 50px;
  --padding-5xl: 24px;
  --padding-base: 16px;
  --padding-21xl: 40px;
  --padding-3xs: 10px;
  --padding-xl: 20px;
  --padding-xs: 12px;
  --padding-mini: 15px;
  --padding-9xs: 4px;
  --padding-sm: 14px;
  --padding-5xs: 8px;

  /* Border radiuses */
  --br-5xs: 8px;
  --br-xs: 12px;
  --br-9xs: 4px;
  --br-3xs: 10px;
  --br-31xl: 50px;
  --br-5xl: 24px;
  --br-sm: 14px;
}
