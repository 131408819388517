.label-text {
  position: relative;
  letter-spacing: 0.1px;
  line-height: 143%;
}
.state-layer,
.stylefilled-stateenabled-s {
  display: flex;
  align-items: center;
  justify-content: center;
}
.state-layer {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-5xl);
}
.stylefilled-stateenabled-s {
  border-radius: 100px;
  background-color: #0092ff;
  height: 40px;
  overflow: hidden;
  flex-direction: column;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--neutrals-50-white);
  font-family: var(--font-raleway);
}
