.preguntas-frecuentes2 {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.text {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 150%;
}
.title {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.icround-keyboard-arrow-down-icon {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.header3,
.text1 {
  align-self: stretch;
}
.header3 {
  background-color: var(--color-aliceblue-200);
  border-bottom: 1px solid var(--color-cornflowerblue-100);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-sm) var(--padding-xl);
  gap: var(--gap-29xl);
}
.text1 {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 150%;
}
.body,
.dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.body {
  align-self: stretch;
  border-bottom: 1px solid var(--color-whitesmoke-100);
  justify-content: center;
  padding: var(--padding-5xl);
  color: var(--color-gray-200);
}
.dropdown {
  border-radius: var(--br-3xs);
  background-color: var(--neutrals-50-white);
  border: 1px solid var(--color-aliceblue-200);
  overflow: hidden;
  justify-content: flex-start;
}
.body1,
.dropdown,
.text3 {
  align-self: stretch;
}
.text3 {
  position: relative;
  line-height: 140%;
}
.body1 {
  border-bottom: 1px solid var(--color-whitesmoke-200);
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-5xl);
  color: var(--color-dimgray-200);
  font-family: var(--font-roboto);
}
.pgina-web {
  text-decoration: underline;
  color: var(--color-dodgerblue-100);
}
.text6 {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 150%;
  display: flex;
  align-items: center;
  width: 516px;
  flex-shrink: 0;
}
.block-12,
.faqs {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  text-align: left;
  font-size: var(--font-size-base);
}
.block-12 {
  background-color: var(--neutrals-50-white);
  align-items: center;
  padding: var(--padding-81xl);
  gap: var(--gap-29xl);
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--color-cornflowerblue-100);
  font-family: var(--font-poppins);
}

@media (max-width: 767px) {
  .block-12 {
    background-color: var(--neutrals-50-white);
    align-items: center;
    padding: 10px;
    gap: var(--gap-29xl);
    text-align: center;
    font-size: var(--font-size-21xl);
    color: var(--color-cornflowerblue-100);
    font-family: var(--font-poppins);
  }
}

