.image-97-icon4 {
  position: relative;
  width: 116px;
  height: 43px;
  object-fit: cover;
}
.marker-02-icon2 {
  position: relative;
  width: 20px;
  height: 20px;
}
.santiago-chile1 {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 143%;
  font-weight: 600;
  display: inline-block;
  width: 273px;
  flex-shrink: 0;
}
.location2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-2xs);
}
.instagram-icon4 {
  position: relative;
  width: 32px;
  height: 32px;
}
.block-14,
.redes-sociales5 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.block-14 {
  flex: 1;
  flex-direction: column;
}
.casa-matriz1,
.div2 {
  position: relative;
  line-height: 143%;
}
.casa-matriz1 {
  align-self: stretch;
  letter-spacing: 0.1px;
}
.div2 {
  flex: 1;
  letter-spacing: 0.15px;
  font-weight: 600;
}
.phone7 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-2xs);
}
.vuesaxlinearmobile-icon1 {
  position: relative;
  width: 11px;
  height: 15px;
}
.vuesaxlinearmobile-container {
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.all-content-inner3,
.block-1-group,
.block-31 {
  align-items: flex-start;
  justify-content: flex-start;
}
.block-31 {
  flex: 1;
  display: none;
  flex-direction: column;
  gap: var(--gap-base);
}
.all-content-inner3,
.block-1-group {
  align-self: stretch;
  display: flex;
}
.block-1-group {
  flex-direction: row;
  gap: var(--gap-21xl);
}
.all-content-inner3 {
  background-color: var(--color-darkslateblue);
  flex-direction: column;
  padding: var(--padding-11xl) var(--padding-81xl);
  text-align: left;
  font-size: var(--text-subtitle2-size);
  color: var(--neutrals-50-white);
  font-family: var(--font-poppins);
}
